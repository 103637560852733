import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allDataLoaded
    ? _c(
        VContainer,
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs8: "" } },
                [
                  _c("view-title", {
                    attrs: {
                      description:
                        (_vm.brand && _vm.brand.location_description) || null
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticClass: "FAB-Extended-PrimaryColor-Enabled",
                  attrs: { xs4: "" }
                },
                [
                  _vm.canAccessConfigs
                    ? _c(
                        VLayout,
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { type: "submit", flat: "" },
                              on: { click: _vm.goToBrandSettings }
                            },
                            [
                              _c(VIcon, { attrs: { left: "", dark: "" } }, [
                                _vm._v("mdi-settings")
                              ]),
                              _vm._v("Configuration\n        ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VLayout,
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        "padding-right": "10px"
                      },
                      attrs: { "justify-end": "" }
                    },
                    [
                      _vm.isClosed
                        ? _c(
                            "div",
                            { staticClass: "Temporarily-closed noselect" },
                            [
                              _c(
                                VIcon,
                                { attrs: { color: "#ffe381", small: "" } },
                                [_vm._v("mdi-circle")]
                              ),
                              _c(
                                "span",
                                { staticClass: "Temporarily-closed-text" },
                                [_vm._v(_vm._s(_vm.brandReopenMessage))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VTabs,
                    { attrs: { "hide-slider": "" } },
                    [
                      _c(
                        VTab,
                        {
                          attrs: {
                            replace: "",
                            to: { name: "brand-overview-menus" }
                          }
                        },
                        [_vm._v("Menu")]
                      ),
                      _vm.brand && _vm.brand.is && _vm.brand.is.pickup_supported
                        ? _c(
                            VTab,
                            {
                              attrs: {
                                replace: "",
                                to: { name: "brand-overview-pickup" }
                              }
                            },
                            [_vm._v("Service Hours")]
                          )
                        : _vm._e(),
                      this.brand &&
                      this.brand.is &&
                      this.brand.is.delivery_supported === true
                        ? _c(
                            VTab,
                            {
                              attrs: {
                                replace: "",
                                to: { name: "brand-overview-delivery" }
                              }
                            },
                            [_vm._v("Delivery Hours")]
                          )
                        : _vm._e(),
                      _c(
                        VTab,
                        {
                          attrs: {
                            replace: "",
                            to: { name: "brand-overview-closure" }
                          }
                        },
                        [_vm._v("Station Closure")]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCard,
                    [
                      _c(
                        VContainer,
                        { attrs: { "grid-list-xl": "" } },
                        [
                          _c(
                            VLayout,
                            { attrs: { row: "", wrap: "" } },
                            [_c("router-view")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }