<template>
  <v-container v-if="allDataLoaded">
    <v-layout row wrap>
      <v-flex xs8>
        <view-title :description="(brand && brand.location_description) || null" />
      </v-flex>
      <v-flex xs4 class="FAB-Extended-PrimaryColor-Enabled">
        <v-layout justify-end v-if="canAccessConfigs">
          <v-btn type="submit" flat @click="goToBrandSettings">
            <v-icon left dark>mdi-settings</v-icon>Configuration
          </v-btn>
        </v-layout>
        <v-layout justify-end style="margin-top: 5px; padding-right: 10px;">
          <div v-if="isClosed" class="Temporarily-closed noselect">
            <v-icon color="#ffe381" small>mdi-circle</v-icon>
            <span class="Temporarily-closed-text">{{ brandReopenMessage }}</span>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-tabs hide-slider>
          <v-tab replace :to="{ name: 'brand-overview-menus' }">Menu</v-tab>
          <v-tab
            v-if="brand && brand.is && brand.is.pickup_supported"
            replace
            :to="{ name: 'brand-overview-pickup' }"
            >Service Hours</v-tab
          >
          <v-tab
            replace
            :to="{ name: 'brand-overview-delivery' }"
            v-if="this.brand && this.brand.is && this.brand.is.delivery_supported === true"
            >Delivery Hours</v-tab
          >
          <v-tab replace :to="{ name: 'brand-overview-closure' }">Station Closure</v-tab>
        </v-tabs>
        <v-card>
          <v-container grid-list-xl>
            <v-layout row wrap>
              <router-view />
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import * as BrandHoursHelpers from '@/views/sites/helpers';
import { UserRoles } from '@/constants';

export default {
  async beforeRouteLeave(to, from, next) {
    if (this.isModified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    const currentDate = DateTime.local();
    return {
      brand: null,
      showBrandHoursDialog: false,
      brandDateTime: {
        minDate: currentDate.toISODate().substr(0, 10),
        showDate: false,
        date: currentDate.toISODate().substr(0, 10),
        time: null,
      },
      calendarLoaded: false,
    };
  },
  computed: {
    ...mapState('sites', ['active_brand', 'active_site']),
    ...mapState('calendar', ['events']),
    ...mapGetters('adminPanel', {
      hasRole: 'hasRole',
      userHasAnyRole: 'userHasAnyRole',
    }),
    ...mapGetters('calendar', {
      getEvents: 'getEvents',
    }),
    canAccessConfigs() {
      // add more permissions here if necessary
      return [UserRoles.admin, UserRoles.im_user, UserRoles.site_operator].some((r) =>
        this.hasRole(r),
      );
    },
    brandStatus() {
      return this.brand.brand_status.is_closed;
    },
    brandTimeZone() {
      return `Time (${this.brand.timezone})`;
    },
    brandName() {
      return this.brand.name;
    },
    isClosed() {
      const reopenTime = DateTime.fromISO(this.reopenTime);
      const currentTime = DateTime.local();
      return reopenTime > currentTime;
    },
    reopenTime() {
      return this.getReopenTime(this.brand, this.getEvents());
    },
    brandReopenMessage() {
      return this.getReopenMessage(this.reopenTime, this.brand.timezone);
    },
    allDataLoaded() {
      return this.brand && this.brand.id && this.calendarLoaded;
    },
  },
  watch: {
    active_brand: {
      async handler(brand) {
        if (
          !brand ||
          !brand.id ||
          brand.id !== this.$route.params.brand_id ||
          (this.brand && this.brand.id === brand.id)
        )
          return;
        this.brand = cloneDeep(this.active_brand);
        this.calendarLoaded = false;
        await this.$store.dispatch('calendar/fetchCalendarEvents');
        this.calendarLoaded = true;
      },
      immediate: true,
    },
    allDataLoaded(val) {
      if (!val) return;
      this.mapBrandTimes(this.brand);
    },
  },
  methods: {
    ...BrandHoursHelpers,
    mapBrandTimes(brand) {
      try {
        if (!this.brand.brand_status) this.$set(this.brand, 'brand_status', {});
        const reopenAt = this.getReopenTime(brand, this.getEvents());
        if (reopenAt) {
          const todaysDate = new Date();
          const brandCloseTime = new Date(reopenAt);
          const isClosed = brandCloseTime >= todaysDate;
          this.$set(this.brand.brand_status, 'is_closed', isClosed);
          if (!isClosed) {
            this.$set(this.brand.brand_status, 'reopen_at', null);
            this.$set(this.brand, 'reopen_message', '');
          } else {
            this.$set(this.brand.brand_status, 'reopen_at', reopenAt);
          }
        } else {
          this.$set(this.brand.brand_status, 'is_closed', false);
        }
      } catch (err) {
        if (err && err.response && err.response.status !== 404) {
          console.error('could not get config', this.brand.id);
        }
      }
    },
    goToBrandSettings() {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 3,
        title: this.brand.name,
        to: {
          name: 'brand-settings',
          params: { brand_id: this.brand.id, site_id: this.active_site.id },
        },
      });
      this.$router.push({
        name: 'brand-settings',
        params: {
          brand_id: this.brand.id,
        },
      });
    },
  },
};
</script>
<style>
.Temporarily-closed {
  height: 16px;
  font-family: Avenir;
  font-size: 13.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.Temporarily-closed-text:hover {
  text-decoration: none;
}
</style>
